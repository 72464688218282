import Input from 'adel-shared/dist/components/basics/Input';
import InputSelect, { AdelOption } from 'adel-shared/dist/components/basics/InputSelect';
import { Dictionary } from 'adel-shared/dist/models';
import { uniqueId } from 'lodash';
import moment from 'moment';
import React, { FunctionComponent, useEffect, useState } from 'react';
import { Controller, FormContextValues, ValidationOptions } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { tauxChargesPatronales } from '../../../../constants/config.constant';
import useValidation from '../../../../custom-hooks/useValidation';
import { CategorieDossier } from '../../../../enums/Dossiers';
import { CachetDto, CreateArtisteDto, Emploi, LieuDateDto, RemunerationDto, TypePrestation } from '../../../../services/generated/FrontOffice-api';
import { DatesSelectOption } from './EditArtiste';
import MontantBrutInput from './MontantBrutInput'

interface CachetsProps {
	cachets: CachetDto[];
	setCachets: (cachets: CachetDto[]) => void;
	form: FormContextValues<CreateArtisteDto>;
	cachetType: keyof typeof TypePrestation;
	dates: LieuDateDto[];
	datesSelectOptions:DatesSelectOption[];
	setDatesSelectOptions:(value:DatesSelectOption[]) => void;
	groupesEmploi?: any;
	remuneration: RemunerationDto[];
	codeCategorie: CategorieDossier;
	typePrestation: keyof typeof TypePrestation;
	emploi: Emploi;
	styleMusical: string; // styleMusical ID
}

const Cachets: FunctionComponent<CachetsProps> = ({
	cachets,
	setCachets,
	form,
	cachetType,
	dates,
	codeCategorie,
	datesSelectOptions,
	setDatesSelectOptions,
	groupesEmploi,
	remuneration,
	typePrestation,
	emploi,
	styleMusical
}) => {
	const { getRootValidator } = useValidation();
	const cachetValidator = getRootValidator("CreateCachetDto");
	const [selectedDates, setSelectedDates] = useState<LieuDateDto[]>([]);
	const [selectedDate, setSelectedDate] = useState<LieuDateDto>();
	const [hasDateLimite, setHasDateLimite] = useState<boolean>();
	const { t } = useTranslation();

	useEffect(() => {
		if (dates) {
			setSelectedDates(cachets.reduce((acc, currentVal) => {
				return acc.concat(currentVal.dates);
			}, []));
		}
	}, [dates]);

	useEffect(() => {
		if (codeCategorie === CategorieDossier.InteretGeneral
		|| codeCategorie === CategorieDossier.AideResidences
		|| codeCategorie === CategorieDossier.AideSpecifique
		) {
			setHasDateLimite(false);
		} else {
			setHasDateLimite(true);
		}
	}, [codeCategorie]);

	const addCachet = () => {
		let temporaryId = uniqueId('TEMPORARY_');
		const newCachet: CachetDto = {
			id: temporaryId,
			dates: [],
			montantBrut: undefined,
			tauxChargesPatronales: undefined,
			count: undefined
		}
		let updateCachets = [...cachets]
		updateCachets = [...updateCachets, newCachet]
		setCachets(updateCachets)
	}

	const deleteCachet = (id:string) => {
		const updateCachets = [...cachets]

		for (const i in updateCachets) {
			if (id === updateCachets[i].id) {
				if(datesSelectOptions.find(elem => TypePrestation[elem.typePresta] === TypePrestation[cachetType])) {
					const update = [...datesSelectOptions];
					const datesToAddToOptions = updateCachets[i]?.dates?.map(date => ({
						label: date.description ? moment(date.date).format('DD/MM/YYYY')  + " (" + date.description +")" : moment(date.date).format('DD/MM/YYYY'),
						value: date
					}));

					update.find(elem => TypePrestation[elem.typePresta] === TypePrestation[cachetType]).options = update
						.find(elem => TypePrestation[elem.typePresta] === TypePrestation[cachetType])
						.options.concat(datesToAddToOptions);

					setDatesSelectOptions(update);
				}
				setSelectedDates(selectedDates.filter((x: LieuDateDto) => updateCachets[i].dates.find(c =>  x.id !== c.id)));
				updateCachets.splice(updateCachets.indexOf(updateCachets[i]), 1);
			}
		}
		setSelectedDate(null);
		setCachets(updateCachets);
	}

	const handleChangeObject = (value: LieuDateDto | string, index: number, propertyName: string, selectedAll?:boolean) => {
		const updateCachets = [...cachets];

		switch(propertyName) {
			case 'lieuDateIds' :
				if(selectedAll) {
					setSelectedDates(dates);
					const update = [...datesSelectOptions];
					update.find(elem => TypePrestation[elem.typePresta] === TypePrestation[cachetType]).options.length = 0;
					setDatesSelectOptions(update);
					updateCachets[index].dates = dates;
				} else {
					setSelectedDates([...selectedDates, value as LieuDateDto]);
					updateCachets[index].dates.push(value as LieuDateDto);

					if(datesSelectOptions.find(elem => TypePrestation[elem.typePresta] === TypePrestation[cachetType])) {
						const update = [...datesSelectOptions];
						update.find(elem => TypePrestation[elem.typePresta] === TypePrestation[cachetType]).options = update
							.find(elem => TypePrestation[elem.typePresta] === TypePrestation[cachetType])
							.options.filter(a => a.value.id !== (value as LieuDateDto).id);
						setDatesSelectOptions(update);
					}
				}

				break;
			case 'montantBrut' :
				updateCachets[index].montantBrut = parseFloat(value as string);
				break;
			case 'tauxChargesPatronales':
				updateCachets[index].tauxChargesPatronales = parseFloat(value as string);
				break;
			case 'count':
				updateCachets[index].count = parseFloat(value as string);
				break;
			default:
				break;
		}

		setCachets(updateCachets);
	}

	const deleteDate = (itemClicked: LieuDateDto, index: number) => {
		const updateCachets = [...cachets]
		updateCachets[index].dates = updateCachets[index].dates.filter((x: LieuDateDto) => x.id !== itemClicked.id);
		setCachets(updateCachets);
		setSelectedDates(selectedDates.filter((x: LieuDateDto) => x.id !== itemClicked.id));
		setSelectedDate(null);

		if(datesSelectOptions.find(elem => TypePrestation[elem.typePresta] === TypePrestation[cachetType])) {
			const updatedDates = [...datesSelectOptions];
			const datesCurrentPresta = updatedDates.find(elem => TypePrestation[elem.typePresta] === TypePrestation[cachetType]).options;
			datesCurrentPresta.push({
				label: itemClicked.description ? moment(itemClicked.date).format('DD/MM/YYYY') + " (" + itemClicked.description +")" : moment(itemClicked.date).format('DD/MM/YYYY'),
				value: itemClicked
			});
			datesCurrentPresta.sort((a, b) => {
				if(a.value.date && b.value.date) {
					return a.value.date > b.value.date ? 1 : -1;
				}
				return 0;
			});
			setDatesSelectOptions(updatedDates);
		}
	}

	const optionsDate = () => {
		let opts: AdelOption<LieuDateDto>[] | AdelOption<string>[] = [];

		const allDates = datesSelectOptions.find(elem => TypePrestation[elem.typePresta] === TypePrestation[cachetType]);
		
		if (allDates) {
			opts = allDates.options;
		} else {
			opts = [];
		}

		const isAllDatesOpt = opts.some((x: any) => x.value === 'all');

		if(!isAllDatesOpt && opts.length > 1) {
			opts.unshift({
				label: t('createFolder.contratsCachets.allDates'),
				value: "all"
			});
		}
		return opts;
	}
  
	

	return (<>
		{cachets.map((x, i) => (
			<div className="creationDossier__littleBlock" key={i}>
				<h3>
					<button onClick={() => deleteCachet(x.id)}>
						<i className="far fa-minus-circle" aria-hidden="true"></i>
					</button>
					{t(`createFolder.contratsCachets.${cachetType}`)}
				</h3>
				<div className="creationDossier__row">
					<Controller
						control={form.control}
						name={`datesCachets${cachetType}${i}`}
						as={({ name }) => (
							<InputSelect<LieuDateDto | string>
								name={name}
								label={`${t('createFolder.form.dates')}`}
								classname="inputSelect"
								options={optionsDate()}
								errors={form.errors}
								onChange={(value) => {
									if(typeof value !== "string") {
										handleChangeObject(value, i, 'lieuDateIds');
										setSelectedDate(value);
									} else {
										handleChangeObject(value, i, 'lieuDateIds',true);
									}
								}}
								value={selectedDate}
								placeholder={t("common.select")}
							/>
						)}
					/>
					<div className="listItems">
						<label>{t("createFolder.lieuxDates.date-added")+"*"}</label>
						<div className="listItemsContent">
							{cachets[i].dates?.map((date: LieuDateDto, index: number) => (
								<span key={'dates'+index}>
									<span>
										{dates.find(d => d.id == date.id).description ? 
										moment(date.date).format('DD/MM/YYYY')  + " (" + dates.find(d => d.id == date.id).description +")" : 
										moment(date.date).format('DD/MM/YYYY') }
										</span>
									<i className="far fa-trash-alt" onClick={() => deleteDate({...date, description: dates.find(d => d.id == date.id).description}, i)}></i>
									{cachets[i].dates.length === 1 || cachets[i].dates.indexOf(date) === cachets[i].dates.length - 1 ? '' : ', '}
								</span>
							))}
							{!cachets[i].dates || cachets[i].dates?.length === 0 && (
								<div className="errorMessage">
									{t("createFolder.lieuxDates.no-date")}
								</div>
							)}
						</div>
					</div>
				</div>
				<div className="creationDossier__row">
					<MontantBrutInput
						name={`montantCachet${cachetType}${i}`}
						step={1}
						form={form}
						value={cachets[i].montantBrut}
						label={t('createFolder.form.montantCachet')}
						handleChangeObject={handleChangeObject}
						index={i}
						estSalaire={false}
						remuneration={remuneration}
						typePrestation={typePrestation}
						emploi={emploi}
						groupesEmploi={groupesEmploi}
						styleMusical={styleMusical}
					/>
				</div>
				<div className="creationDossier__row">
					<Input name={`nbCachet${cachetType}${i}`}
						label={`${t('createFolder.form.nbCachet')}*`}
						type="number"
						min={hasDateLimite ? dates?.length : 0}
						placeHolder="0"
						reference={form.register(hasDateLimite
							? { validate: (value: number) => (value == cachets[i].dates.length) || `${t('createFolder.contratsCachets.number-cachet-error', { number: cachets[i].dates.length })}` }
							: cachetValidator?.["Count"]
						)}
						errors={form.errors}
						value={cachets[i].dates.length}
						onChange={(value: string) => {
							handleChangeObject(value, i, 'count');
						}}
					/>
					<Input name={`tauxCharges${cachetType}${i}`}
						label={`${t('createFolder.form.tauxCharges')}*`}
						type="number"
						min={0}
						max={tauxChargesPatronales * 100}
						placeHolder="0%"
						/** @ts-ignore */
						reference={form.register({
							required: {value: true, message: t("validation-messages.required")},
							min: { value: 0.000000001, message: t("validation-messages.greater-than", {ComparaisonValue: 0})},
							max: {value: tauxChargesPatronales * 100, message: t("validation-messages.less-than-or-equal", {ComparaisonValue: tauxChargesPatronales * 100})}
						})}
						errors={form.errors}
						value={cachets[i].tauxChargesPatronales}
						onChange={(value: string) => {
							handleChangeObject(value, i, 'tauxChargesPatronales');
						}}
					/>
				</div>
			</div>
		))}
		{cachets.length < 1 ?
			<div className="creationDossier__addElement" onClick={addCachet}>
				<i className="fas fa-plus-circle"></i>
				{t("createFolder.contratsCachets.addCachet")}
			</div>
			: datesSelectOptions.find(elem => TypePrestation[elem.typePresta] === TypePrestation[cachetType])?.options.length > 0 &&
			<div className="creationDossier__addElement" onClick={addCachet}>
				<i className="fas fa-plus-circle"></i>
				{t("createFolder.contratsCachets.addCachet")}
			</div>
		}
	</>);
}

export default Cachets;